<template>
  <CRow>
    <CCol>
      <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow">
        <CCard>
          <CCardHeader><font-awesome-icon icon="cog" fixed-width /> <strong>預約設定</strong> </CCardHeader>
          <CCardBody>
            <CInput label="開放預約天數" v-model.number="availableDay" type="number" />
            <CInput label="預約間隔天數" v-model.number="intervalDay" type="number" />
            <CInput label="每日可預約數" v-model.number="limit" type="number" />
          </CCardBody>
          <CCardFooter class="text-right">
            <CButton type="button" color="primary" class="mr-1" @click="update">確認更新</CButton>
          </CCardFooter>
        </CCard>
      </b-overlay>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: "",
  data: () => ({
    loading: true,
    availableDay: 0,
    limit: 0,
    intervalDay: 0,
  }),
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$http.get("/reserve/setting").then((response) => {
        if (response.data.success) {
          this.availableDay = response.data.result.availableDay;
          this.limit = response.data.result.limit;
          this.intervalDay = response.data.result.intervalDay;
        } else {
          this.$notify({ type: "error", text: response.data.message });
        }
        this.loading = false;
      });
    },
    update() {
      this.loading = true;
      let params = {
        availableDay: this.availableDay,
        limit: this.limit,
        intervalDay: this.intervalDay,
      };
      this.$http.post("/reserve/setting", params).then((response) => {
        if (response.data.success) {
          this.$notify({ type: "success", text: "更新成功" });
        } else {
          this.$notify({ type: "error", text: response.data.message });
        }
        this.loading = false;
      });
    },
  },
};
</script>